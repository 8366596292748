.dashboardPopup_main_container {
  .profile-section-card {
    padding: 0px 100px 20px 100px;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    padding: 0 0 25px;
  }

  @media (max-width: 360px) {
    .profile-section-card {
      padding: 25px !important
    }
  }

  .dashboard_main_container {
    padding: 40px 100px;
    min-height: 350px;
    // background: linear-gradient(111.34deg, #152945 0%, #0c2d61 100%);
    background: linear-gradient(111.34deg, #FF0000, #33332F);
    // background-image: url('../../../public/assets/home/Background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 80px;

    @media (max-width: 900px) and (min-width: 600px) {
      padding: 70px 0;
      min-height: 42vh;
    }


    @media (max-width: 595px) {
      padding: 50px 0;
      min-height: 335px;
      max-height: 335px;
    }

    .brandLogo {
      cursor: pointer;

      img {
        width: 110px;
        height: 150px;

        @media (max-width: 1700px) {
          width: 90px;
          height: 112px;
        }

        @media (max-width: 595px) {
          width: 80px;
          height: 100px;
        }
      }
    }

    .balanceContent_top {
      color: #fff;
      margin-top: 45px;

      @media (max-width: 400) {
        h4 {
          font-size: 33px;
        }

        h2 {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
    }

    .dashboard_bannerBg {
      position: absolute;
      bottom: 0;
      right: 0;

      @media (max-width: 595px) {
        display: none;
      }
    }

    .dashboard_bannerBg_mob {
      position: absolute;
      bottom: 0;
      right: 0;
      display: none;

      @media (max-width: 414px) {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;

        .arrowInput_img {
          margin-left: 146px;
        }
      }
    }


    // payment selector 
    .payment-method-selector {
      width: 310px !important;
    }

    //   lang drowpdown
    .languageDropdown_dashboard {
      position: relative;
      top: -20px;

      .languageDropdown_FormCcontrol_dashboard {
        @media (max-width: 900px) and (min-width: 600px) {
          min-width: 125px;
        }

        @media (max-width: 595px) {
          min-width: 95px;
        }
      }

      .MuiInputBase-root {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.02em;
        color: #fff;
        min-width: 131px;
        height: 40px;
        z-index: 222;
        justify-content: center;
        text-transform: uppercase;

        @media (max-width: 900px) and (min-width: 600px) {
          min-width: 125px;
          height: 36px;
          font-weight: 500;
        }

        @media (max-width: 595px) {
          font-size: 13px;
          min-width: 95px;
          height: 36px;
          font-weight: 500;
          text-align: left;
          justify-content: flex-start;
        }

        em {
          font-style: normal;
        }

        .MuiSelect-select {
          display: flex;
          justify-content: flex-start;
          padding: 0;
          padding-left: 20px !important;

          @media (max-width: 595px) {
            overflow: hidden;
            text-overflow: clip;
            width: 27px;
          }
        }

        fieldset {
          border: 1px solid #fff;
          height: 40px;

          @media (max-width: 900px) and (min-width: 600px) {
            height: 38px;
          }

          @media (max-width: 595px) {
            height: 38px;
          }
        }
      }
    }
  }

  //   tabs section starts
  .dashboard_tabsSection_main {
    min-width: 450px;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    bottom: -30px;

    @media (min-width: 1024px) {
      z-index: 222;
      position: absolute;
      bottom: -29px
    }

    @media (min-width: 768px) {
      z-index: 222;
      position: absolute;
      bottom: -29px
    }

    @media (min-width : 700px) {
      z-index: 222;
      position: absolute;
      bottom: -29px
    }

    @media (min-width: 600px) {
      z-index: 222;
      position: absolute;
      bottom: -29px
    }

    @media (max-width: 595px) {
      padding: 3px;
      min-width: 70%;
      position: absolute;
      left: 20px;
      bottom: -40px;
      z-index: 22222;
    }

    @media (max-width: 375px) {
      left: 16px;
    }

    @media (max-width: 360px) {
      left: 8px;
    }

    .dashboard_tabsSection {
      position: relative;
      bottom: 0;
      width: auto;

      // @media (max-width: 1200px) and (min-width: 900px) {
      //   width: 85%;
      // }

      // @media (max-width: 900px) and (min-width: 700px) {
      //   width: 98%;
      // }

      @media (max-width: 595px) {
        width: 99.5%;
      }

      @media (max-width: 300px) {
        width: 100%;
      }

      .Tabs {
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        /* Tab Navigation */

        ul.nav {
          width: 100%;
          display: flex;
          background-color: transparent;
          align-items: center;
          justify-content: flex-start;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          @media (max-width: 1208px) and (min-width: 800px) {
            width: 100%;
          }

          @media (min-width:1024px) {
            display: contents;
          }

          @media (max-width:768px) {
            display: contents;
          }

          @media (min-width:768px) {
            display: contents;
          }

          @media (max-width: 595px) {
            width: 100%;
            flex-wrap: nowrap;
          }

          @media (max-width: 335px) {
            width: 100%;
            overflow-x: scroll;
            flex-wrap: nowrap;
          }
        }

        ul.nav li {
          list-style: none;
          cursor: pointer;
          transition: all 0.7s;
          background: #fff;
          text-align: center;
          margin-right: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 0px;
          width: 217.5px;
          height: 53px;
          border-radius: 5px;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #3a3a3f;
          box-shadow: none;

          &:focus-visible {
            outline: -webkit-focus-ring-color auto 0;
          }

          @media (max-width: 1208px) and (min-width: 800px) {
            min-width: 19.2%;
          }

          @media (max-width: 595px) {
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 3px;
            width: 182px;
            height: 53px;
            margin-right: 0px;
          }

          @media (max-width: 395px) {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 3px;
            width: 166px;
            height: 53px;
            margin-right: 0px;
          }
        }

        ul.nav li:nth-child(2) {
          border-top-left-radius: 2px;
        }

        ul.nav li:hover {
          background: #fff;
        }

        ul.nav li.active {
          color: #fff;
          border-bottom: 0 solid #25b583;
          background: #25b583;

          @media (max-width: 595px) {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 2px;
          }

          @media (max-width: 335px) {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 31.2%;
          }
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-left: 12px;
        position: relative;
        top: 10px;

        @media (max-width: 1208px) and (min-width: 800px) {
          font-weight: 400;
          font-size: 17px;
          line-height: 21px;
        }

        @media (max-width: 595px) {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }

  @media (max-width : 414px) {
    .css-7enrj6-MuiTypography-root {
      font-size: 25px !important;
    }

    .css-1jgg44t-MuiTypography-root {
      h2 {

        span {
          font-size: 35px !important;
        }
      }
    }
  }
}

@media (max-width : "414px") {

  .css-1yp120-MuiSvgIcon-root {
    right: 8px !important;
  }
}

@media (max-width : "540px") {
  .dashboardPopup_main_container .profile-section-card {
    padding: 50px !important;
  }
}

@media (max-width : "414px") {
  .dashboardPopup_main_container .profile-section-card {
    padding: 16px !important;
  }
}

@media (max-width : "1000px") {
  .topupCard_main_container .formArea_topup_main .enterRfid_textSection .arrowInput_img {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 595px) {
  #arrowImg {
    margin-left: -252px;
    margin-top: 17px;
    margin-bottom: 12px;
  }
}

@media (min-width: 0px) {
  .css-17dmwv5-MuiGrid-root>.MuiGrid-item {
    padding-left: 0px !important;
  }
}