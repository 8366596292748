.not-found-container {
    background: linear-gradient(111.34deg, #152945 0%, #0C2D61 100%);
    width: 100%;
    min-height: 100vh;
    padding: 40px 100px;

    .brandLogo {
        cursor: pointer;

        @media (max-width : 600px) {
            margin-left: -70px;
        }

        img {
            width: 110px;
            height: 150px;

            @media (max-width: 1700px) {
                width: 90px;
                height: 112px;
            }

            @media (max-width: 595px) {
                width: 80px;
                height: 100px;
            }
        }
    }

    //   lang drowpdown
    .languageDropdown_homePage {
        position: relative;
        top: 30px;

        @media (max-width: 900px) and (min-width: 600px) {
            top: 0;
        }

        @media (max-width: 595px) {
            top: 0;
            margin-left: 100px;
        }

        @media (max-width: 400px) {
            top: 0;
            margin-left: 50px;
        }


        .languageDropdown_FormCcontrol_homePage {
            @media (max-width: 900px) and (min-width: 600px) {
                min-width: 125px;
            }

            @media (max-width: 595px) {
                min-width: 120px;
            }
        }

        .MuiInputBase-root {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.02em;
            color: #fff;
            min-width: 131px;
            height: 40px;
            justify-content: center;
            text-transform: uppercase;

            @media (max-width: 900px) and (min-width: 600px) {
                min-width: 125px;
                height: 36px;
                font-weight: 500;
            }

            @media (max-width: 595px) {
                font-size: 13px;
                min-width: 120px;
                height: 36px;
                font-weight: 500;
            }

            em {
                font-style: normal;
            }

            .MuiSelect-select {
                display: flex;
                justify-content: flex-start;
                padding: 0;
                padding-left: 20px !important;
            }

            fieldset {
                border: 1px solid #fff;
                height: 40px;

                @media (max-width: 900px) and (min-width: 600px) {
                    height: 38px;
                }

                @media (max-width: 595px) {
                    height: 38px;
                }
            }
        }
    }

    .not-found-img {
        img {
            max-width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
        }
    }
}