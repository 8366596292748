.requestRefundCard_main_container {
  .formArea_requestRefund_main {
    max-width: 100%;
    margin-top: 15px;
    padding: 20px;

    // @media (min-width:1024px) {
    //   .MuiGrid-root {
    //     column-gap: 10px;
    //   }
    // }

    .rfidPass_num {
      @media (max-width: 600px) {
        margin: 0 0 25px;
      }

      .inputLabel {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: #808191;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 10px;

        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .MuiInputBase-root {
        padding: 12px 20px;
        max-width: 343px;
        height: 54px;
        border: 1px solid #808191;
        border-radius: 6px;


        @media (max-width : 600px) {
          max-width: 200px;
        }

        @media (max-width: 500px) {
          max-width: 170px;
        }

        @media (max-width: 414px) {
          max-width: 322px;
        }

        @media (min-width : 1024px) and (max-height : 1100px) {
          max-width: 200px;
        }

        @media (min-width : 1440px) {
          max-width: 322px;
        }

        &::placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
          color: #808191 !important;
        }

        input {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #000000;

          &::placeholder,
          &::-moz-placeholder,
          &::-webkit-input-placeholder {
            color: #808191 !important;
          }
        }

        &:after,
        &:before {
          border-bottom: 0 !important;
        }
      }
    }

    .labelText_checkbox {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #000000;
      margin-bottom: 0;

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0;
      }

      span {
        color: #4263eb;
        text-decoration: underline;
      }
    }

    .gridEmpty_refund {
      @media (min-width:595px) {
        display: none;
      }
    }

    button {
      padding: 15px 0px;
      width: 250px;
      height: 57px;
      background: #4263eb;
      border-radius: 6px;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;
      margin-top: 40px;
      box-shadow: none;

      @media (max-width: 595px) {
        width: 100%;
        height: 55px;
        background: #4263eb;
        border-radius: 6px;
        font-weight: 600;
        font-size: 20px;
      }

      &:disabled {
        background: #bcc5db;
      }
    }
  }
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 0;
}